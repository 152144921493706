import VideoModal from "../../components/modal/VideoModal";
const Introduction = () => {
    return (
        <>
            <h2>Video Guide</h2>
            <div>
                For help understanding this section of the report, please see <VideoModal buttonText="video guide" videoUrl="6cucU18fGDo?si=kZboNqdjEKHUbvKn" /> on The Analytic Sample.
            </div>

            <h2>Introduction</h2>
            <p>
                When the team at COACHE began developing the research design of the Faculty Retention and Exit Study,
                we quickly discovered the great variability in institutional capacity for collecting data about faculty who receive
                outside offers. We had some knowledge of the extent to which our university partners were (or were not)
                gathering, sharing and utilizing information about faculty departures. Retention actions, however—efforts to
                keep someone who has an outside offer in hand—were almost universally in provosts&rsquo; data blind spot. A few
                of our partners had robust platforms for managing such departure and retention data, but many had never
                compiled the data centrally.
            </p>
            <p>
                The opportunity to marshal that knowledge is why, even though the primary instrument of this study is a
                questionnaire, we consider this a <em>data</em> project, not merely a survey. Without a single response, COACHE can
                help academic leaders learn a great deal just by curating the data that their institutions already have—or could
                have with a small effort.
            </p>
            <p>
                From your data alone, you can begin to see which schools, colleges and divisions have the highest rates of
                outside offers, and which quarters are having the most success with their retention actions. Perhaps there are
                concerning differences in the number and quality of counteroffers by gender or race, or lessons to learn about
                the levers that are more effective for faculty in one discipline versus another.
            </p>
            <p>
                Beyond the “win/loss” ratios of retentions and departures, administrative data can shed light on the institutional
                <em> investments</em> made in faculty. How much time and treasure were diverted in establishing, developing, and
                rewarding faculty who departed? Considerations could include startup funds, teaching releases, and leaves that
                will never be recouped because a faculty member left soon after the time or money was spent.
            </p>
            <p>
                Such data collection routines are also an opportunity to take into account the <em>contributions</em> that a faculty member
                made during their time at your institution. You can consider not just awards and honorifics, grants earned, and
                research productivity, but also teaching excellence, service leadership, and other factors describing what the
                neoliberal university might think of as “return on investment,” or “faculty replacement value”.
            </p>
            <p>
                This section of the Quantitative Report offers some of the data that some participating universities provided
                to us in advance of survey administration. Looking for patterns in the cleaned, aggregated, and comparative
                administrative data can help academic leaders raise questions about the “business as usual” processes and
                protocols of faculty retention and departure. You might ask, “What can be learned without a survey?”
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    Is your institution doing all it can to gather, share, and use information about faculty departures? What
                    about retention actions?
                </li>
                <li>
                    Are you losing your most productive scholars or your best teachers or faculty who serve with distinction
                    in leadership roles? What about their contributions to the diversity and success of your institution?
                </li>
                <li>
                    Compared to faculty at other universities, how soon into their tenure at your institution are faculty seeking
                    outside offers?
                </li>
            </ul>
        </>
    )
}

export default Introduction;