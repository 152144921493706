import VideoModal from "../../components/modal/VideoModal";


const Introduction = () => {
    return (
        <>
            <h2>Video Guide</h2>
            <div>
                For help understanding this section of the report, please see <VideoModal buttonText="video guide" videoUrl="Go3ctcnlbXE?si=f5QirqqmeZhw6VeP" /> on Overall Impressions.
            </div>

            <h2>Introduction</h2>
            <p>
                This COACHE survey concludes with broad questions about the overall impression faculty have about their
                institutions (or former institutions). What “one thing” could have convinced them to stay? (The response
                choices map largely to the COACHE Faculty Job Satisfaction Survey, thereby allowing for a comparison across
                data sources for partners engaged in both studies.) Were faculty satisfied with efforts to retain them? And would
                they recommend their departments as places to work?
            </p>
            <p>
                These items shed the nuance cultivated in prior questions (see “Weighing the Factors” above) in favor of
                simpler terms. The data can be used to identify whether “leavers” have a favorable impression of their home
                institution. Indeed, as our pilot results have shown, even those “successfully” retained may harbor negative
                opinions toward their faculty colleagues or administration.
            </p>
            <p>
                Such data can be usefully deployed in communication about these results to broader university populations,
                whether deans, chairs, or faculty.
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    Who should receive this information? Who is the best “messenger” for specific recipient groups or for
                    certain messages?
                </li>
                <li>
                    Are there times where senior leadership must be engaged and other times where their presence can inhibit
                    dialogue? What type of “power” will most effectively generate engagement?
                </li>
                <li>
                    What is the bottom-line message you believe these data convey? Is there one sentiment or piece of
                    information that you want your faculty to remember from your communication? How might the message
                    be interpreted, or mis-interpreted?
                </li>
                <li>
                    Are there certain institutional events or initiatives that you might leverage to inform the timing of your
                    communications? How might the channel you select, the location you host a meeting in, or the technology
                    you utilize to house information affect accessibility?
                </li>
                <li>
                    How will you solicit feedback, and what kind of feedback do you want to receive? What strategies might
                    you use to ensure that marginalized voices are given safe means for sharing their perspectives?
                </li>
            </ul>

        </>
    )
}

export default Introduction;