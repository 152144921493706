interface FileStructure {
    [sectionName: string]: string[];
}

export const fileStructure: FileStructure = {
    "AboutThisReport": [],
    "TheAnalyticSample": [
        "Introduction",
        "DemographicCharacteristics",
        "InstitutionalPersistence",
        "ResponseRates"
    ],
    "WeighingTheFactors": [
        "Introduction",
        "AverageRank",
        "TopFactors",
        "Comments",
        "ResponsesToOtherPrompts"
    ],
    "Spouses&Partners": [
        "Introduction",
        "MaritalStatus",
        "Career",
        "ResponsesToOtherPromptsCareer",
        "EmploymentStatus",
        "Positions",
        "ResponsesToOtherPromptsPositions"
    ],
    "Search": [
        "Introduction",
        "Time",
        "Motivation",
        "ApplicationsAndOffers",
        "InitialContact",
        "ResponsesToOtherPromptsInitialContact"
    ],
    "NegotiationProcess": [
        "Introduction",
        "FirstNotification",
        "Seriousness",
        "CounterofferNotification",
        "YourInstitutionsHandling"
    ],
    "NegotiationTerms": [
        "Introduction",
        "OriginOutsideOffers",
        "RankTenureTitle",
        "Compensation",
        "PermissibleUseStartupFund",
        "ResponsesToOtherPromptsPermissibleUseStartupFund",
        "CounterOffersSoughtReceived",
        "ResponsesToOtherPromptsCounterOffersSoughtReceived",
        "YourCounterofferProposedChanges",
        "ResponsesToOtherPromptsYourCounterofferProposedChanges",
        "YourCounterofferExtentMatched",
        "YourCounterofferFacultyAssessmentOfComparison",
        "YourCounterofferWhyItDidNotMaterialize"
    ],
    "TheTransition": [
        "Introduction",
        "Treatment",
        "TextBasedResponse"
    ],
    "OverallImpressions": [
        "Introduction",
        "TopChange",
        "ResponsesToOtherPromptsTopChange",
        "Satisfaction",
        "Recommendation",
        "Likelihood",
        "TextBasedResponses"
    ],
    "PreemptiveRetentions": [
        "Introduction",
        "ProposedChanges",
        "ResponsesToOtherPromptsProposedChanges",
        "Comments",
        "AdditionalDetails"
    ],
    "Appendix": [
        "BriefSummary",
        "VideoGuides",
        "YourCommitmentToConfidentiality",
        "EligiblePopulation",
        "Administration",
        "ReportingAnalysis",
        "References",
        "Contact",
        "TheAnalyticSample-GrantsFromExternalSources",
        "TheAnalyticSample-AcademicAnalytics",
        "TheAnalyticSample-MissingData",
        "TheAnalyticSample-ResponseRates",
        "NegotiationTerms-RankTenureTitle"
    ]
}

export const getNextSection = (currentSection: string, currentSubsection: string) => {
    if (currentSubsection === "AboutThisReport") {
        return "TheAnalyticSample/Introduction"
    }

    let subsectionIndex = fileStructure[currentSection].indexOf(currentSubsection);
    let sections = Object.keys(fileStructure);
    let sectionIndex = sections.indexOf(currentSection);


    // if subsectionIndex is at last position, go to the next section and return the first subsection
    if (subsectionIndex === fileStructure[currentSection].length - 1) {
        let newSection = sections[sectionIndex + 1];
        return `${newSection}/${fileStructure[newSection][0]}`;
    }

    return `${currentSection}/${fileStructure[currentSection][subsectionIndex + 1]}`;
}

export const getPreviousSection = (currentSection: string, currentSubsection: string) => {

    if (currentSection === "TheAnalyticSample" && currentSubsection === "Introduction") {
        return "AboutThisReport"
    }

    let subsectionIndex = fileStructure[currentSection].indexOf(currentSubsection);
    let sections = Object.keys(fileStructure);
    let sectionIndex = sections.indexOf(currentSection);


    // if subsectionIndex is at first position, go to the previous section and return the last subsection
    if (subsectionIndex === 0) {
        let newSection = sections[sectionIndex - 1];
        return `${newSection}/${fileStructure[newSection][fileStructure[newSection].length - 1]}`;
    }

    return `${currentSection}/${fileStructure[currentSection][subsectionIndex - 1]}`;
}

export const getFirstSection = () => {
    return Object.keys(fileStructure)[0];
}

export const getLastSection = () => {
    let sectionKeys = Object.keys(fileStructure);
    let lastSection = sectionKeys[sectionKeys.length - 1];

    if (fileStructure[lastSection]) {
        let subsections = fileStructure[lastSection];
        return fileStructure[lastSection][subsections.length - 1];
    }
    return lastSection;
}