import VideoModal from "../../components/modal/VideoModal";


const Introduction = () => {
    return (
        <>
            <h2>Video Guide</h2>
            <div>
                For help understanding this section of the report, please see <VideoModal buttonText="video guide" videoUrl="R4zrErMA2Ao?si=KTl8rlLeW5FrLuVH" /> on Spouses & Partners.
            </div>
            
            <h2>Introduction</h2>

            <p>
                Since the publication of <em>The Two Body Problem</em> in 2004, scholars and administrators have been searching for the
                most effective approaches to what universities can do to accommodate the career needs of spouses and
                partners. Indeed, our review of institutional exit surveys revealed that dual-career academic couples are a
                universal concern. While O&rsquo;Meara, Lounder and Campbell (2014) found that administrators and leavers&rsquo;
                colleagues often believe family to be a primary issue (and one over which they have little control) among the
                reasons why faculty leave, their study revealed that the departing faculty themselves are less likely to discuss
                family matters as primary reasons to leave. So, how much do spousal factors matter?
            </p>
            <p>
                In our pilot study, we learned that faculty often cite employment opportunities for a spouse or partner among
                the compelling factors both to stay <em>and</em> to leave. We observed then that, if it is so difficult to <em>recruit</em> a dual-career
                couple, then universities must enjoy some “home-field advantage” in <em>retaining</em> them. To be sure, a partner&rsquo;s
                career needs and aspirations complicate the cultivation of an outside offer. Even if a faculty member was not
                able to accept an outside offer because it did not include an adequate solution for his or her partner, he or she
                may begin a new search or seriously consider another offer in the very near future.
            </p>
            <p>
                Therefore, routinely addressing this “problem” of current faculty, not just prospects, could be a very successful
                long-term retention strategy. The enlightened chair, dean, or provost will continue beyond the retention action
                to engage faculty in finding a more suitable resolution to their dual career issues. By ensuring that the partners
                of their most desirable faculty have positions that are stable and aligned with their professional qualifications
                and aspirations, institutions leverage their comparative advantage in the faculty labor market. Under such care,
                the couple will be hard-pressed to find an equally satisfying alternative in the dual-career marketplace.
            </p>
            <p>
                This module of the survey explores how married or partnered respondents&rsquo; consideration of an outside offer
                might take into account their spouses&rsquo; or partners&rsquo; careers. The survey asks whether the faculty member or
                his/her spouse received the outside offer first, then investigates the type of employment held by spouses and
                partners. Are they academics, administrators, or employed outside of higher education?
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    What dual-hire processes or accommodations are currently practiced at your institution? How do they vary
                    across units, and what might be learned from the more successful units?
                </li>
                <li>
                    What processes or indicators might identify when a faculty member&rsquo;s spouse/partner is going on the
                    market, particularly if he or she is employed at another institution?
                </li>
                <li>
                    How can your institution ensure communication across departments and divisions when spousal hiring is
                    part of the retention equation? How could you measure the effectiveness of such processes?
                </li>
                <li>
                    Which relationships with regional partners—whether academic, nonprofit, public sector or industry—can
                    be explored to improve the likelihood of finding employment opportunities for spouses?
                </li>
            </ul>

        </>
    )
}

export default Introduction;