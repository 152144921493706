const VideoGuides = () => {

    return (
        <div className="page-layout">
            <h3>Video Guides</h3>
            <div>
                <h4>About This Report</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/bJOP4tJ5o2M?si=mLe9Vl0b_6Yv3Ns-" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 1 - The Analytic Sample</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/6cucU18fGDo?si=kZboNqdjEKHUbvKn" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 2 - Weighing the Factors</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/YjLcweN0_3Q?si=sPGaiYLoqKf71Wpe" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 3 - Spouses & Partners</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/R4zrErMA2Ao?si=KTl8rlLeW5FrLuVH" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 4 - The Search</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ga9Ct9GXnNQ?si=RP1NNt_aToU7KHYT" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 5 - The Negotiation Process</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/ukBURqOferA?si=c0hLkqXUGXjV9XcX" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 6 - The Negotiation Terms</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/cUuDmwgnrs0?si=OZtkNtS185CRNfHv" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 7 - The Transition</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/iC_jvcaPSiQ?si=Wb2JozPTZ8I6TTqS" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 8 - Overall Impressions</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Go3ctcnlbXE?si=f5QirqqmeZhw6VeP" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
            <div>
                <h4>Section 9 - Preemptive Retentions</h4>
                <iframe width="560" height="315" src="https://www.youtube.com/embed/G_ChkKN0Y7E?si=utGqllzquuB7-hO2" title="YouTube video player"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
            </div>
        </div>
    )
}

export default VideoGuides;